@use "~@els/els-styleguide-core/scss/settings/spaces";

$async-loading-color: #3498db;

.c-tsp-async-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  &__progress {
    height: spaces.$els-space-1o8;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: spaces.$els-space-1o8;
      background-color: $async-loading-color;
      animation: getWidthLoading 3s ease-in infinite;
    }
  }

  &__icon {
    border-radius: 50%;
    border-top: spaces.$els-space-1o8 solid $async-loading-color;
    border-right: spaces.$els-space-1o8 solid $async-loading-color;
    width: spaces.$els-space-3o4;
    height: spaces.$els-space-3o4;
    margin-top: spaces.$els-space-1o2;
    margin-left: spaces.$els-space-3o4;
    -webkit-animation: spin .5s linear infinite;
    -moz-animation: spin .5s linear infinite;
    animation: spin .5s linear infinite;
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes getWidthLoading {
	100% { width: 100%; }
}
