@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";
@use "../base/vars";

.u-tsp-align-top {
  vertical-align: top;
}

.o-tsp-form {
  .o-tsp-form__group {
    padding: 1rem 0;
  }

  .o-tsp-form__row {
    display: block;
    width: 100%;
  }

  .o-tsp-form__col {
    display: inline-block;
  }

  .o-tsp-form__controls {
    &>button {
      margin-left: 1rem;

      &:first-child {
        margin-left: 0px;
      }
    }
  }
}

.u-tsp-cohort-title {
  font-weight: 700;
  color: #0065b4;
}

.c-tsp-installments,
.c-tsp-product-selection,
.c-tsp-student-rosters {
  .c-els-table__head {
    .c-els-table__row {
      .c-els-table__cell--header {
        border-bottom: 1px solid #e2e2e2;
      }
    }
  }

  .c-els-table__body {
    .c-els-table__row {
      &:last-child {
        .c-els-table__cell {
          border-bottom: 1px solid #e2e2e2;
        }
      }
    }
  }
}

.c-tsp-product-entitlement-justify-content {
  justify-content: flex-start;
}

.c-tsp-installment-edit-view-padding-left {
  padding-left: 2.5rem;
}

.c-tsp-cohort-detail {
  &>h4 {
    color: #007398;
  }

  .c-els-tab-group__item:last-child {
    margin-right: 0;
  }
}

.u-tsp-cohort-status {
  text-transform: capitalize;
  color: #cecece;
  font-weight: 700;
  font-style: italic;

  &--active {
    color: #16a820;
  }
}

.c-tsp-product-review {
  &__section {
    border-top: 1px solid #e2e2e2;
  }
}

.height-adjustment {
  height: 50vh;
}

.c-tsp-wrap-content {
  flex-flow: row wrap;
  justify-content: flex-start;

  .c-tsp-flex-alignment {
    width: 245px !important;
  }
}

#sendRosterRequestModal {
  .c-els-modal__window {
    overflow: initial;
  }
}

.c-tsp-installments {
  &__search-button {
    margin-top: 1.2rem !important;
  }

  .c-els-table__body {
    .c-els-table__row {
      &:nth-of-type(even) {
        @include sass-mq-config.mq($until: tablet) {
          background-color: white;
        }
      }
    }
  }
}

#tblCohortInstallments {
  .c-els-table__row {
    .c-els-table__cell--field-expanded {
      @include sass-mq-config.mq($until: mobile) {
        display: table-cell;

        .u-tsp-visibility--hidden {
          display: none !important;
        }
      }
    }
  }
}
.c-tsp-payment-status {
  margin-left: 10%;
  max-width: 63%;
  
  @include sass-mq-config.mq($until: desktop) {
    max-width: 100%;
  }

  @include sass-mq-config.mq($until: mobile) {
    white-space: break-spaces;
  }

  .c-els-table {
    &--list__header {
      display: none;
    }

    &__row, &:last-child {
      .c-els-table__cell-header {
        @include sass-mq-config.mq($until: tablet) {
          border-bottom: keylines.$els-thick-keyline-width solid colors.$els-color-n2;
        }
      }
    }
    
    &__body {
      .c-els-table__cell {
        border-bottom: none !important;
      }
    }

    &__row {
      line-height: 1;

      &:first-child {
        .c-els-table__cell {
          padding-top: spaces.$els-space-1x;
        }
      }
    }

    &__cell {
      padding-bottom: spaces.$els-space-1o2;
      padding-top: spaces.$els-space-1o2;
    }
  }

  &__table {
    &__column {
      &--0 {
        width: 11%;
      }
      &--1 {
        width: 21%;
      }
      &--2 {
        width: 13%;
      }
    }
  }
}

.c-tsp-cohort-page {
  &__in-flight-cohort-message {
    background-color: #d7e9ef;
  }
}

.c-tsp-student-payments {
  &__filter-box {
    max-width: 350px
  }

  &-closed-message {
    background-color: #d7e9ef;
    padding: spaces.$els-space-3o4 0;
  }
}

.c-els-button--warn-custom {
  border-color: colors.$els-color-warn;
}

#ngContractForm {
  overflow: auto;
  align-items: unset;
  padding: spaces.$els-space-2x 0;

  .c-els-modal__window {
    overflow: initial;
    max-height: inherit;
    margin-top: auto;
    margin-bottom: auto;
  }

  .ng-contract-buttons {
    @include sass-mq-config.mq($until: desktop) {
      flex-direction: column;
    }
  }
}

#field-wrap-studentGroupFilter {
  .c-els-field__icon {
    z-index: unset;
  }
}

#studentConfirmModal {
  z-index: unset;

  .group-entitlement-only-checkbox {
    max-width: fit-content;
  }
}

.group-entitlement-only-tooltip {
  .c-els-flyout {
    background-color: colors.$els-color-n2;
  }
}

.c-els-group-icon {
  min-height: 0;
  z-index: unset;

  .c-els-progress-indicator {
    &__item--active .c-els-progress-indicator__item-node {
      color: colors.$els-color-secondary;
      border-color: colors.$els-color-secondary;
    }
    &__item--circle .c-els-progress-indicator__item-node {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1rem;
      border-width: 0.15rem;
    }
  }
}
