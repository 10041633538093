@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/colors";

.c-tsp-tags-input {
  padding-left: 0 !important;
  height: auto;
  min-height: 2.5rem;
  opacity: 1 !important;
  border: 1px solid #cecece;
  border-bottom: 2px solid #666;

  .c-dropdown-search__option {
    z-index: 2;
  }

  .c-tag-group__tag {
    border-radius: 0;
  }

  &__input {
    border: none;
    width: 100%;
    padding: spaces.$els-space-1o2 spaces.$els-space-1x1o2 0 spaces.$els-space-3o4;

    &--transparent {
      position: absolute;
      background-color: transparent;
      display: none;
    }
  }

  &__tags {
    padding-right: spaces.$els-space-1x1o2;
  }
  .o-tsp-icon-svg-margin-top-1o2{
    margin-top: - spaces.$els-space-1o2 !important;
  }

  &__disabled {
    cursor: not-allowed;
    opacity: 0.5 !important;
    border-color: colors.$els-color-n3;
    padding-right: 2.75rem;
  }

  &:focus-within {
    padding: 0 !important;

    #txtTag {
      padding-right: 2.5rem;

      &:focus {
        padding-bottom: spaces.$els-space-1o2;
      }
    }
  }
}
