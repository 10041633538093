#editDateModal {
  overflow: auto;

  .c-els-modal__window {
    overflow: initial;
  }
}

.c-tsp-invoice-date {
  &__date {
    width: 4rem;
  }
}
