@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";

.c-tsp-layout {
  &__header {
    border-bottom: keylines.$els-thick-keyline-width solid colors.$els-color-n0;
  }

  &__side-nav {
    border-right: keylines.$els-thick-keyline-width solid colors.$els-color-n0;
  }

  &__body {
    >div {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      margin: 0;
      padding: 0;
    }
  }

  &__facet-filters {
    display: none;
  }

  &--sidebar {
    border-right: none;
    padding: 0 !important;
    width: auto !important;
  }

  &__message {
    justify-content: center;
    text-align: center;
    transition: all .5s ease-in-out;
    color: white;
    height: 0;
    position: relative;

    &-info {
      * {
        color: white !important;
      }
    }


    &--overlap {
      z-index: 100000;
    }

    &--show {
      height: auto;
      padding: spaces.$els-space-1o2;
    }

    &--success {
      background-color: colors.$els-color-confirm-on-dark;
    }

    &--failed {
      background-color: colors.$els-color-warn;
    }
  }
}
.has-facets {
  .c-tsp-layout {
    &__facet-filters {
      display: block;
      min-width: 18vw;
      border-right: keylines.$els-thick-keyline-width solid colors.$els-color-n0;
      @include sass-mq-config.mq($from: tablet, $until: desktop) {
        min-width: 20vw;
      }
      @include sass-mq-config.mq($until: tablet) {
        width: 100%;
        border: none;
        height: auto;
      }
    }
  }
}

.c-tsp-flex-align-items-baseline {
  align-items: baseline;
}

.c-tsp-flex-align-self-baseline {
  align-self: baseline;
}
