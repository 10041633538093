@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/colors";

.c-tsp-workflow-status {
  .c-els-tooltip-container {
    cursor: default;
  }
  &__list {
    display: flex;
    cursor: default;
    .u-els-debuttonize {
      white-space: nowrap;
    }
    &--item {
      margin-left: spaces.$els-space-1o4;
      width: spaces.$els-space-3o4;
      height: spaces.$els-space-3o4;
      border-radius: 50%;
      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__button-edit {
    border: none;
    margin-right: spaces.$els-space-3o4;
  }

  &__tooltip {
    min-width: 300px !important;
    .c-els-tooltip__close {
      color: colors.$els-color-secondary;
    }
  }
}

#cohortWorkflowStatusModal {
  .reset-status {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
}

.c-tsp-status-header {
  color: inherit;
  display: inline;
  .u-els-debuttonize {
    color: inherit;
  }
  &__tooltip {
    min-width: 300px !important;
    .c-els-tooltip__close {
      color: colors.$els-color-secondary;
    }
    &-content {
      margin-top: spaces.$els-space-1o4;
    }
  }
  &__container {
    display: inline-block;
  }

  &__icon {
    // color: colors.$els-color-secondary;
    margin-left: spaces.$els-space-1o4;
    &:hover {
      color: colors.$els-color-primary;
    }
  }
}
