@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";

.u-els-billing-queue-active-static {
  & .u-tsp-border-active {
    position: static;
  }
}
.c-els-table__cell {
  .c-els-table__cell-content {
    a.c-els-remove-hover-border {
      &:hover {
        border: none;
      }
    }
  }
}
.c-tsp-billing-queue {
  &__filter {
    @include sass-mq-config.mq($from: desktop) {
      justify-content: flex-end;
    }
  }

  &__button-comment {
    border: none;
    &:hover {
      border: none;
    }
  }

  &__icon-comment {
    width: spaces.$els-space-1x1o2 !important;
    max-width: none;

    &:hover {
      filter: invert(47%) sepia(93%) saturate(485%) hue-rotate(342deg) brightness(92%) contrast(98%);
    }

    &--medium-size {
      width: spaces.$els-space-2x !important;

      &:hover {
        filter: invert(47%) sepia(93%) saturate(485%) hue-rotate(342deg) brightness(92%) contrast(98%);
      }
    }
  }

  &__icon-money-refund {
    width: spaces.$els-space-1x1o2 !important;
    max-width: none;
  }

  &__table {
    &__column {
      &--0 {
        width: 1%;
      }
      &--1 {
        width: 10%;
      }
      &--2 {
        width: 16%;
      }
      &--3 {
        width: 17%;
      }
      &--4 {
        width: 10%;
      }
      &--5 {
        width: 12%;
      }
      &--6 {
        width: 16%;
      }
      &--7 {
        width: 18%;
      }
    }
  }
}

.c-tsp-installment-comment {
  &__table-border {
    border-bottom: keylines.$els-thin-keyline-width solid colors.$els-color-n2;
  }

  &__status-confirm {
    background-color: #d7e9ef;
    padding: spaces.$els-space-3o4 0;
  }

  &__table {
    &__column {
      &--0 {
        width: 30%;
      }
      &--1 {
        width: 30%;
      }
      &--2 {
        width: 40%;
      }
    }
  }
}
