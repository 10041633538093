@use '~@els/els-styleguide-core/scss/core' as *;
@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";

.c-tsp-product-selection {
  &__tool-box {
    @extend .o-els-flex-layout;
    @extend .o-els-flex-layout--bottom;
    @extend .o-els-flex-layout--wrap;
    @extend .o-els-flex-layout--space-between;

    &--hide-filter {
      @extend .o-els-flex-layout--right;
    }
  }

  &__filter-box {
    display: flex;
    flex-wrap: wrap;

    @include sass-mq-config.mq($until: mobile) {
      width: 100%;
    }
    &--product {
      width: 275px;
      margin-bottom: spaces.$els-space-1x;
      margin-right: spaces.$els-space-1x;

      @include sass-mq-config.mq($until: mobile) {
        width: 100%;
        margin-right: 0;
      }

      @include sass-mq-config.mq($until: wide) {
        width: 250px;
      }
    }

    &--term {
      width: 300px;
      margin-bottom: spaces.$els-space-1x;

      @include sass-mq-config.mq($until: mobile) {
        width: 100%;
      }

      @include sass-mq-config.mq($until: wide) {
        width: 250px;
      }
    }
  }

  &__filter-column {
    padding: 0 spaces.$els-space spaces.$els-space spaces.$els-space;
    width: 25%;
    @include sass-mq-config.mq($from: tablet, $until: desktop) {
      width: 33.3333%
    }
    @include sass-mq-config.mq($until: tablet) {
      width: 100%;
      border: none;
      height: auto;
    }
  }
  &__filters {
    .c-els-field {
      display: inline-block;
      margin-left: 4%;
      width: 48%;
      &:first-child {
        margin-left: 0;
      }
    }
    @include sass-mq-config.mq($until: mobile) {
      .c-els-field {
        margin-left: 0;
        display: block;
        width: 100%;
        margin-top: spaces.$els-space;
      }
    }
  }
  &__search-box {
    font-size: 1.25rem;
    border: 1px solid colors.$els-color-n6;
    padding: spaces.$els-space-2x;
    > .c-els-field__label {
      font-size: 1.5rem;
      margin-bottom: 5px;
    }
    .c-els-field__input {
      font-size: 1.25rem;
    }
  }

  &__no-results {
    margin-top: spaces.$els-space-3x;
    padding: spaces.$els-space;
    border-top: keylines.$els-thin-keyline-width solid colors.$els-color-n3;
    border-bottom: keylines.$els-thin-keyline-width solid colors.$els-color-n3;
  }

  &__message {
    color: colors.$els-color-n4;
    font-size: 2.5rem;
    text-align: center;
    padding: spaces.$els-space-5x;
  }

  &__search-result {
    h3 {
      color: colors.$els-color-primary;
      border-bottom: 2px solid colors.$els-color-n2;
    }
  }
  &__search-item {
    border-bottom: 2px solid colors.$els-color-n2;
    .o-els-flex-layout__item {
      padding: spaces.$els-space;
    }
    .product-image {
      @include sass-mq-config.mq($from: tablet) {
        width: 75px;
      }
      img {
        border: 1px solid colors.$els-color-n2;
      }
    }
    .product-edition {
      color: colors.$els-color-n9;
      font-weight: 600;
    }
    .product-type-name {
      button {
        margin-bottom: 0.2rem;
        color: colors.$els-color-n9;
        font-size: 1.1rem;
        font-weight: 600;
        svg {
          margin-left: 5px;
          color: colors.$els-color-secondary;
          vertical-align: bottom;
        }
        &:hover {
          color: colors.$els-color-primary;
          svg {
            color: colors.$els-color-primary;
          }
        }
      }
    }
    .product-name {
      font-size: 1.5rem;
    }
    .product-author {
      font-size: 1rem;
    }
    .product-copy-year {
      font-size: 1rem;
    }
    .product-buttons {
      button {
        display: block;
        margin-top: 10px;
        width: 11rem;
        &:first-child {
          margin-top: 0;
        }
        svg {
          margin-left: 0.5rem;
          margin-bottom: -0.1rem;
        }
      }
    }
  }
  &__detail-section {
    .c-els-accordion__group {
      border-top: 2px solid #e2e2e2 !important;
      &.c-els-accordion__group--expanded:last-child {
        border-bottom: 2px solid #e2e2e2;
      }
      button {
        font-size: 1.75rem;
      }
      .c-els-accordion__body-content {
        font-size: 1rem;
        border: none;
        ul, li {
          list-style: none;
        }
        li {
          text-indent: -1.5em;
          padding-left: 1.7em;
          &:before {
            content: "\2022 ";
            color: colors.$els-color-primary-on-dark;
            margin-right: 1rem;
          }
        }
      }

      .c-els-accordion__button-text {
        color: colors.$els-color-primary;
      }
    }
    h3 {
      font-size: 1.75rem;
      color: colors.$els-color-primary;
    }
    .back-button {
      font-size: 1.5rem;
      span {
        color: colors.$els-color-secondary;
      }
      svg {
        margin-bottom: -0.2rem;
      }
    }
    .product-author {
      font-size: 1.25rem;
      color: colors.$els-color-n8;
    }
    .product-desc {
      margin-bottom: spaces.$els-space-2x;
      font-size: 1.25rem;
    }
    .product-technical {
      .product-technical-flatforms {
        img {
          display: inline-block;
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  &__info-column {
    width: 235px;
    min-width: 235px;
    &__product-image {
      border: 1px solid colors.$els-color-n2;
      width: 100%;
      height: auto;
      margin-bottom: spaces.$els-space;
    }
    .product-info {
      font-size: 1rem;
      line-height: 1.5;
      margin: spaces.$els-space-2x 0;
      span {
        color: colors.$els-color-n6;
      }
    }
    @include sass-mq-config.mq($from: tablet, $until: desktop) {
      width: 140px;
      min-width: 140px;
    }
  }
  &__body-column {
    flex-grow: 1;
    .product-image {
      width: 174px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .product-detail-tab__group {
      .c-els-accordion__button:focus {
        outline: none !important;
      }
    }
  }
  &__function-column {
    width: 21%;
    min-width: 21%;
    padding-left: 1.5rem;
    .c-els-button--large {
      width: 100%;
    }
    @include sass-mq-config.mq($until: desktop) {
      width: 30%;
      min-width: 30%;
    }
  }
  &__technical-support {
    li {
      svg {
        float: left;
        width: 2.25rem;
        color: colors.$els-color-secondary;
      }
      button {
        display: block;
        margin-left: 2.75rem;
        font-size: 1.125rem;
        padding-top: 0.375rem;
        line-height: 1.5rem;
      }
      &:after {
        clear: both;
      }
    }
  }
  &__info-message {
    font-size: 0.9rem;
    svg {
      color: colors.$els-color-secondary;
      float: left;
    }
    &--warn {
      svg {
        color: #c83727;
      }
    }
    &__content {
      display: block;
      margin-left: 2rem;
      line-height: 1.5rem;
    }
    &__header {
      font-weight: 600;
      font-size: 1rem;
    }
    &:after {
      clear: both !important;
    }
  }
  &__related-item {
    font-size: 1.25rem;
    margin-bottom: spaces.$els-space-1x1o2;
  }
  &__product-vst-logo {
    height: 15px;
    width: auto;
    margin-top: 1.5rem;
  }
  &__product-intel-logo {
    width: auto;
    margin-top: 1.5rem;
  }
  &__product-ads {
    img {
      width: 235px;
      height: auto;
      cursor: pointer;
      @include sass-mq-config.mq($until: desktop) {
        width: 222px;
      }
    }
  }
  .product-detail-buttons {
    button {
      width: 100%
    }
    .c-els-productDetail-addProduct {
      span {
        text-align: center;
        display: block;
      }
    }
  }
  .c-els-accordion__group {
    border-top: none;
  }
  .c-els-accordion__body-content {
    font-size: 1rem;
    border-top: 2px solid #e2e2e2;
  }
  .c-els-accordion__group--expanded > * > .c-els-accordion__button-text,
  .c-els-accordion__group--expanded > * > * > .c-els-accordion__button-text {
    border: none;
  }
  .c-els-accordion__button-text {
    font-weight: 600;
  }
  .c-els-accordion__group--expanded {
    border-bottom: none;
  }
  &__tools {
    width: 3rem;
    min-width: 3rem;
    position: relative;
    button {
      display: block;
      margin: 0.8rem 0 0 0.5rem;
      border-bottom: none;
      height: 1rem;
      &:first-child {
        margin-top: 0;
      }
    }
    &__container {
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  &__form {
    .c-els-field--checkbox {
      .c-els-field__label {
        margin-top: 0.5rem;
      }
    }
    .u-tsp-product-header {
      font-weight: 700;
      color: #202020;
    }
  }
  &__action-btn {
    width: spaces.$els-space-5x;
  }
  .u-els-whitespace {
    white-space: nowrap !important;
  }
}
.u-tsp-approval-action {
  font-weight: 600;
  text-transform: uppercase;
  &--rejected {
    color: colors.$els-color-warn-on-dark;
  }
  &--approved {
    color: colors.$els-color-confirm;
  }
  &--view {
    cursor: pointer;
  }
}
#removeProductConfirmModal {
  .c-els-modal__window {
    border-top-color: colors.$els-color-warn;
    .c-els-modal__close {
      position: relative;
    }
  }
}
.c-tsp-lms-modal {
  h3 {
    color: colors.$els-color-primary;
    font-size: 1.75rem;
  }
  .c-els-field--inline-checkbox {
    .c-els-field--checkbox,
    .c-els-field--radio {
      display: inline-block;
      margin-left: 2em;
      &:first-child {
        margin-left: 0;
      }
    }
    .o-els-icon-svg {
      margin-left: 1rem;
      color: colors.$els-color-secondary;
    }
  }
  .c-els-field--inline-dropdown {
    .c-els-field {
      display: inline-block;
    }
    .c-els-tooltip-container {
      margin-top: 0.75em;
      margin-left: 1rem;
      svg {
        color: colors.$els-color-secondary;
      }
    }
  }
  .c-els-divider {
    border-bottom-color: colors.$els-color-n6;
  }
  .inline-input-row {
    .inline-input-lable {
      line-height: 2.5rem;
    }
    .c-els-field {
      max-width: 10rem;
      @include sass-mq-config.mq($until: desktop) {
        max-width: 100%;
      }
    }
  }

  .c-els-field {
    &--disabled {
      svg {
        color: colors.$els-color-n6;
      }
    }
  }
}
.c-tsp-remove-product-comfirm-modal {
  position: relative;
  h3 {
    font-weight: 600;
  }
  &__top-icon {
    display: inline-block;
    position: absolute;
    left: -0.5rem;
    top: 0.2rem;
    color: colors.$els-color-warn;
  }
}
.c-tsp-product-approval {
  &__back-button {
    font-size: 1.5em;
    svg {
      vertical-align: -4px;
    }
  }

  &__no-approval {
    margin-top: spaces.$els-space-3x;
    padding: spaces.$els-space;
    border-top: keylines.$els-thin-keyline-width solid colors.$els-color-n3;
    border-bottom: keylines.$els-thin-keyline-width solid colors.$els-color-n3;
  }

  &__history-table {
    .date-time-column {
      width: 20%;
    }
    .contact-column {
      width: 20%;
    }
    .action-column {
      width: 60%;
    }
  }
}
.c-tsp-review-product-selection {
  &__product-item {
    &__main {
      position: relative;
      padding-left: 100px;
    }
    &__image-thumb {
      position: absolute;
      top: 0;
      left: 0;
      width: 75px;
      img {
        margin-top: 0.25rem;
        width: 75px;
        height: auto;
        border: 1px solid silver;
      }
    }
    &__title {
      font-size: 1.5rem;
    }
    &__platform {
      display: inline-block;
      margin: 30px 0px 20px 0px;
      min-width: 50%;
      border: 2px solid colors.$els-color-secondary;
      padding: spaces.$els-space;
      svg {
        margin-right: spaces.$els-space;
        color: colors.$els-color-secondary;
      }
      button {
        margin-left: spaces.$els-space;
      }
      .u-els-display-flex {
        padding-top: 0.4rem;
      }
      @include sass-mq-config.mq($until: tablet) {
        width: 100%;
      }
    }
    &__right-column {
      width: 200px;
      text-align: right;
      @include sass-mq-config.mq($until: tablet) {
        width: 100%;
      }
    }
    p {
      line-height: 1.5em;
    }
  }
  .product-price {
    font-size: 2.5rem;
    color: colors.$els-color-n9;
    &--small {
      color: colors.$els-color-n6;
      font-size: 1.25rem;
    }
  }
  .column-title {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  }
}
.eols-divider--product-listing {
  margin: 30px 0px;
}

#markProductAsApproved {
  .c-els-modal__window {
    @extend .u-els-padding;
  }
}
.c-tsp-mark-product-approved {
  display: flex;
  flex-direction: row;
  margin-right: spaces.$els-space-2x;
  margin-top: 0.2rem;
  &__icon {
    background-color: colors.$els-color-secondary;
    border-radius: 50%;
    align-self: flex-start;
    margin-right: spaces.$els-space-1o2;
    img {
      width: spaces.$els-space;
      max-width: spaces.$els-space;
      height: spaces.$els-space;
      margin: spaces.$els-space-1o4;
    }
  }
}
.entitlement-students-list-modal-height{
  height:44vh;
}

.c-tsp-product-selection-container {
  .c-tsp-product-selection {
    &__tool-box {
      align-items: center;
    }
  }
}
