@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/colors";

.c-tsp-facet-options {
  &__links {
    li {
      margin-bottom: 0.25rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    button {
      font-size: 1rem;
    }
    a {
      font-size: 1rem;
      display: inline-block;
      .o-els-flex-layout__item--grow {
        padding-top: 0.2rem;
      }
    }
    .c-els-button__text {
      line-height: 1.2;
      &:hover{
        color:colors.$els-color-primary;
      }
      .o-els-flex-layout__item--grow {
        padding-top: 0.2rem;
      }
    }
  }
}
.c-tsp-facet-filters {
  &__title {
    font-size: 1.25rem;
    margin-top: spaces.$els-space;
  }
  &__content {
    font-size: 1rem;
    .c-els-field__label-text-content {
      font-size: 1rem;
    }
  }

  &__checkboxes-group {
    &__button {
      padding-left: 0;
    }
    
    &__body {
      padding-left: 0;
    }
  }

  .c-els-accordion__group--expanded {
    border-bottom: none;
  }
}
