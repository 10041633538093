@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "../base/vars";

.c-tsp-nav-section {
  &--top {
    font-size: 1.25rem;
  }
  &--dropdown {
    .c-els-menu__item {
      font-size: spaces.$els-base-space * 1.125;
    }
    .c-els-menu__window {
      z-index: 999;
      white-space: nowrap;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      border-top: colors.$els-color-n2 solid 1px;
      padding: 0 !important;
      .c-els-menu__list {
        padding: 0;
      }
      .c-els-menu__item {
        padding: 1rem !important;
        border-bottom: colors.$els-color-n7 solid 1px !important;
        cursor: pointer;
        &:last-child {
          border-bottom: none !important;
        }
        .c-els-link {
          border-bottom: none !important;
        }
      }
    }
    &__toggle-button__text {
      &:hover {
        color: colors.$els-color-secondary;
      }
    }
  }

  &--side {
    border-bottom: keylines.$els-thick-keyline-width solid colors.$els-color-n0;
    padding: spaces.$els-space-2x spaces.$els-space-1x1o2 spaces.$els-space;

    .c-els-nav-group__item {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &--alerts {
    .c-els-menu__window {
      width: 21rem;
    }
    .c-els-link {
      color: colors.$els-color-n7;
    }
    &__counter-flag {
      position: absolute;
      top: 5px;
      left: calc(50%);
      font-size: 1rem;
      background-color: colors.$els-color-primary;
      padding: 3px 5px 2px 5px;
      color: vars.$tsp-color-white;
      line-height: 1;
    }
  }

  &--sign-in {
    &__icons {
      white-space: nowrap;
    }
  }
}
