@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/typography";
@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";

.c-tsp-footer {
  width: 100%;
  padding: spaces.$els-space spaces.$els-space 0 spaces.$els-space;

  &__container {
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    border-top: spaces.$els-space-1o8 solid colors.$els-color-primary-on-dark;
    padding: spaces.$els-space-2x 0;

    &:after {
      clear: both;
    }

    @include sass-mq-config.mq($until: tablet) {
      flex-direction: column;
    }
  }
  &__logo {
    max-width: 144px;
    min-width: 144px;
    margin-bottom: spaces.$els-space-3o4;
    margin-right: spaces.$els-space-1x1o2;

    & a {
      border-bottom: none;
    }
  }

  &__content {
    flex-grow: 1;
    margin: 0;
    font-size: typography.$els-fs-base;
    margin-top: spaces.$els-space-1o8;
  }

  &__link {
    float: left;
    margin-right: spaces.$els-space-2x;
    margin-bottom: spaces.$els-space;

    & a {
      display: inline-block;
      color: colors.$els-color-text;
      cursor: pointer;
      &:hover {
        color: colors.$els-color-text;
      }
    }
    @include sass-mq-config.mq($until: tablet) {
      float: none;
      width: auto;
      margin: spaces.$els-space-1o2 0;
    }
  }

  &__relx {
    min-width: 100px;
    max-width: 100px;
    margin-left: spaces.$els-space-1x1o2;
    position: relative;

    & * {
      display: block;
    }

    & a {
      border-bottom: none;
      img {
        width: 100px;
        vertical-align: middle;
      }
    }
    @include sass-mq-config.mq($until: tablet) {
      margin: 0;
      padding-top: spaces.$els-space-1o2;
    }
  }
}

.c-tsp-footer-menu {
  padding: spaces.$els-space-1x1o2 spaces.$els-space-1x1o2 spaces.$els-space spaces.$els-space-1x1o2;
  &__links {
    border-top: keylines.$els-thin-keyline-width solid colors.$els-color-n3;
    padding: spaces.$els-space-1x1o2 0 spaces.$els-space 0;

    @include sass-mq-config.mq($until: mobile) {
      flex-direction: column;
      font-size: 14px;
    }
    
    &__column {
      padding-right: spaces.$els-space;
      padding-bottom: spaces.$els-space-1o2;
      line-height: 1;

      @include sass-mq-config.mq($until: tablet) {
        margin-bottom: spaces.$els-space-1o2;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    a, a:focus, a:active {
      color: colors.$els-color-text;
      line-height: 1.5;
    }
  }
  &__socials {
    text-align: right;
    @include sass-mq-config.mq($until: tablet) {
      text-align: center;
    }

    li {
      list-style: none;
      display: inline-block;
      margin-right: spaces.$els-space-1o2;
      &:last-child {
        margin-right: 0;
      }
      a {
        border: none;
        &:hover, &:focus, &:active {
          border: none;
          text-decoration: none;
        }
        svg {
          width: spaces.$els-space-2x;
          height: spaces.$els-space-2x;
          margin: 0;
        }
      }
    }
  }
}
