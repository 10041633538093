@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/settings/typography";

.c-tsp-error-page {
    &__header {
        font-weight: bold;
    }
    &__message {
        &--content {
            color: colors.$els-color-n7;
            font-size: typography.$els-fs-meta;
        }
    }
    &__actions {
        &--copy {
            color: colors.$els-color-secondary;
            text-decoration: none;
            border-bottom: keylines.$els-thick-keyline-width solid transparent;
            transition: color 0.2s ease, border 0.2s ease;
            
            &:hover {
                color: colors.$els-color-primary;
                border-color: colors.$els-color-primary;
            }
        }
    }
}