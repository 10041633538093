@use '~@els/els-styleguide-core/scss/core' as *;
@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";

.c-tsp-landing-page {
  border-bottom: solid 2px #aaa;
  margin-bottom: 4rem;

  &__hero-image {
    background-size: cover;
    height: 70vh;
    background-position: center top;
  }

  &__video {
    height: 100%;
    position: relative;
    overflow: hidden;

    video {
      object-fit: cover;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  &__line-image--top {
    min-height: 135px;
    background: url(../../assets/images/landing-bg-2.png) no-repeat;
    position: relative;
    background-size: 100% 100%;
    width: 100%;
    z-index: 100;
  }

  &__line-image--bottom {
    height: 195px;
    background: url(../../assets/images/landing-bg-3.jpg) no-repeat;
    position: relative;
    background-size: 100% 100%;
    z-index: 110;
  }

  &__role-select {
    @extend .u-els-width-1o2;
    @extend .u-els-width-1o2\@wide;
    @extend .u-els-width-2o3\@desktop;
    @extend .u-els-width-5o6\@tablet;
    @extend .u-els-width-1o1\@mobile;
    @extend .u-els-padding-1x\@mobile;
    background-color: white;
    float: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: -190px;
    padding: 20px 35px;
    text-align: center;

    h2 {
      margin-top: 10px;
    }

    &__button {
      background-color: #ff8200;
      border: 0.125rem solid transparent;
      font-size: 1.5rem;
      font-weight: bold;
      width: 100%;
      line-height: 1.2;

      &:hover {
        color: white;
        background-color: #d6731c;
      }
    }
  }

  @include sass-mq-config.mq($until: tablet) {
    .c-tsp-landing-page__hero-image {
      display: none; 
    }
    .c-tsp-landing-page__line-image--top {
      background-image: none;
    }
    .c-tsp-landing-page__role-select {
      margin: 0 auto !important;
      .c-els-tooltip--bottom-left::before {
        border: none;
      }
    }
    .c-tsp-landing-page__line-image--bottom {
      height: 135px;
      background-image: none;
    }
  }

  &__tooltip {
    z-index: 9999;
    min-width: 180px;
    max-width: 500px;
  }
}
