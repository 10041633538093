@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/settings/spaces";

#tblInstallmentAmountsHistory {
  .c-els-table__head {
    background-color: colors.$els-color-n1;
    .c-els-table__cell--header:nth-child(3)
     {
      width: 50.5% !important;
    }
    .c-els-table__cell--header:nth-child(2)
     {
      width: 15% !important;
    }
    .c-els-table__cell--header:nth-child(1)
     {
      width: 17% !important;
    }
  }

  thead .c-els-table__cell {
    padding: 0.3rem spaces.$els-space;
  }

  tbody .c-els-table__cell {
    border-bottom: none;
  }
}

#tblInstallmentDataTable {
  th.c-els-table__cell {
    padding: spaces.$els-space-1o2 spaces.$els-space;
  }

  td.c-els-table__cell {
    border-bottom: none;
    padding: 0.3rem spaces.$els-space;
  }
}
