@use "~@els/els-styleguide-core/scss/settings/colors";

.c-tsp-page-not-found {
  &__container {
    display: flex;
    justify-content: center;
    padding: 7.5em 0.5em;
  }

  &__icon {
    padding-right: 2.5em;
    &--page-not-found {
      background: url('../../assets/icons/error-page-not-found.svg') no-repeat;
      width: 12.5em;
      height: 12.5em;
    }
  }

  &__message {
    max-width: 37em;
    font-size: 1.25em;
    padding-top: 2em;
    &--title {
      margin-bottom: 0.8em;
      font-size: 2.5em;
      line-height: 1em;
      color: colors.$els-color-primary-on-dark;
    }

    &--link {
      a {
        border-color: colors.$els-color-secondary;
        &:hover {
          border-color: colors.$els-color-primary;
        }
      }
    }
  }
}
