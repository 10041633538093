@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "../base/vars";

.c-tsp-nav-item {
  color: colors.$els-color-n9;
  cursor: pointer;
  display: block;
  transition: unset;

  &:hover {
    border-bottom: 0;
    color: colors.$els-color-n9;
  }

  &__icon {
    display: inline-block;
  }

  &__text {
    &:hover {
      border-bottom: keylines.$els-thick-keyline-width solid;
      border-color: colors.$els-color-primary;
    }
  }

  &--disabled,
  &--disabled:hover {
    border-color: transparent;
    color: colors.$els-color-n4;
    cursor: not-allowed;

    .c-tsp-nav-item__text,
    .c-tsp-nav-item__text:hover {
      border-color: transparent;
      color: colors.$els-color-text;
      cursor: not-allowed;
    }
  }
}

.c-tsp-nav-item-top {
  border: solid transparent;
  border-width: 2px 0;
  color: colors.$els-color-text;

  &:hover {
    border-color: transparent;
  }

  &--link {
    &:hover {
      border-bottom-color: colors.$els-color-primary;
    }
  }

  &--button {
    font-size: 0.8rem;
  }

  &--login {
    color: colors.$els-color-secondary;
  }

  &--toggle-button {
    &:hover {
      color: colors.$els-color-secondary;
    }
  }
}
