@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/settings/typography";
@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";
@use "../base/vars";

.c-tsp-header {
  .c-els-header__wordmark {
    display: inline-block;
    vertical-align: middle;
    color: colors.$els-color-primary-on-dark;
    font-size: typography.$els-fs-h2;
    margin-top: vars.$half-space;
  }

  @include sass-mq-config.mq($until: wide) {
    .c-els-header__wordmark {
      font-size: typography.$els-fs-h4;
      margin-top: 0.375rem;
    }
  }
}