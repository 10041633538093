@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";

.c-tsp-credit-request-form {
  &__button1 {
    background-color: white;
    border-color: colors.$els-color-secondary;
    color: colors.$els-color-n9;
  }

  &__tax {
    margin-left: spaces.$els-space * 1.75;

    &--input {
      display: none;
    }
  }

  .c-tsp-credit-request-justify-content {
    justify-content: space-between;
  }
}
