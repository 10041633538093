@use "~@els/els-styleguide-core/scss/settings/colors";

.c-tsp-credit-request-queue {
  &__status {
    &-btn {
      border: none;

      &:hover {
        color: colors.$els-color-primary;
      }
    }
  }

  &-reason {
    &__edit-btn {
      border: none;
    }
  }

  &-table {
    &__column {
      &--0 {
        width: 10%;
      }

      &--1 {
        width: 15%;
      }

      &--2 {
        width: 15%;
      }

      &--3 {
        width: 14%;
      }

      &--4 {
        width: 15%;
      }

      &--5 {
        width: 16%;
      }

      &--6 {
        width: 15%;
      }
    }
  }
}

.c-els-link__text {
  &--blue:not(:disabled) {
    &, .c-els-link__text {
      color: colors.$els-color-secondary !important;
      &:hover {
        color: colors.$els-color-n10 !important;
      }
    }
  }

  &--warn:not(:disabled) {
    &, .c-els-link__text {
      color: colors.$els-color-warn !important;
      &:hover {
        color: colors.$els-color-n10 !important;
      }
    }
  }
}
