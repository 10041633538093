// This is for common styles
@use "~@els/els-styleguide-core/scss/core";
@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/settings/typography";
@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";
@use "vars";

html {
  font-size: typography.$els-fs-root;
}

body {
  padding-bottom: 0;
  padding-top: 0;
  overflow-y: scroll;

  color: core.$els-color-text;
  font-family: typography.$els-font-base;
  font-feature-settings: "tnum", "lnum";
  font-size: typography.$els-fs-root;
  font-weight: 400;
  line-height: typography.$els-lh-default;
}

textarea {
  resize: none;
  font-variant-numeric: lining-nums;
}

textarea.c-els-field__input--large {
  height: 8rem;
}

.c-tsp-comment-input {
  height: auto !important;
}

.c-els-table--header-highlight {

  .c-els-table__cell--header,
  .c-els-table__cell-header {
    color: colors.$els-color-secondary;
  }
}

.u-tsp-underline-button {
  border-bottom: 1px solid !important;
}

.c-tsp-button {
  &--disable {
    cursor: not-allowed;
    border: none;
    color: vars.$els-table-row-highlight !important;

    &:hover {
      color: vars.$els-table-row-highlight;
    }
  }
}

.c-tsp-border {
  border: keylines.$els-thick-keyline-width solid colors.$els-color-warn;
  width: -webkit-full-screen !important;
}

.c-tsp-border-bottom {
  border-bottom: keylines.$els-thick-keyline-width solid colors.$els-color-n2;
}

.c-tsp-input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  &__main-input {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    display: block;

    .c-els-field__input {
      height: 2.6rem;
      line-height: 2.6rem;
    }
  }

  &__addon-input {
    display: flex;
  }

  &__append-button {
    margin-left: -1px;
    display: flex;

    button {
      position: relative;
      z-index: 2;

    }
  }

  &__inline-text {
    height: 2.5rem;
    line-height: 2.5rem;
    vertical-align: middle;
  }
}

.c-tsp-inline-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  .c-els-field {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    display: block;
  }

  &__input-addon {
    display: flex;
    margin-top: 1.5em;
    margin-left: 0.5em;
    color: colors.$els-color-primary;
  }
}

.c-tsp-inline-icon {
  position: relative;

  &__icon {
    position: absolute;
    right: -2em;
    top: 0.5em;
    color: colors.$els-color-secondary;
    cursor: pointer;
  }
}

.u-tsp-hint-text {
  font-size: 0.95rem;
  font-style: italic;
}

.u-tsp-border {
  &__bottom {
    border-bottom: 2px solid vars.$els-table-row-highlight;
  }
}

.u-tsp-warning-container {
  border: 1px solid colors.$els-color-warn;
  padding: spaces.$els-base-space;
  color: colors.$els-color-warn;
}

.c-tsp-inline-edit-date {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  &__date {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    display: block;

    .c-els-field__input {
      height: 2.6rem;
      line-height: 2.6rem;
    }
  }

  &__icon {
    display: flex;
  }
}

.c-els-modal__window--visible {
  overflow: visible;
}

.c-tsp-tooltip {
  z-index: 9999;
  min-width: 180px;
  max-width: 500px;

  &__small {
    max-width: 400px;
  }
}

.u-tsp-color-active {
  color: #29A61B;
}

.u-tsp-border-inactive {
  border: keylines.$els-thick-keyline-width solid colors.$els-color-n3 !important;
}

.u-tsp-font-size-1x1o4 {
  font-size: 1.25 * typography.$els-fs-root;
}

.u-tsp-font-size-1x1o2 {
  font-size: 1.5 * typography.$els-fs-root;
}

.u-tsp-font-size-1x3o4 {
  font-size: 1.75 * typography.$els-fs-root;
}

.u-tsp-font-size-2x {
  font-size: 2 * typography.$els-fs-root;
}

.u-tsp-capitalize {
  text-transform: capitalize;
}

.u-tsp-work-break__all {
  word-break: break-all;
}

.u-tsp-work-break {
  word-break: break-word;
}

.u-tsp-installments-name {
  border: none !important;

  @include sass-mq-config.mq($until: tablet) {
    float: left;
    width: auto !important;
  }
}

.u-tsp-width-7x {
  width: spaces.$els-space * 7;
}

.u-tsp-display-inline-flex {
  display: inline-flex;
}

.c-tsp-button-icon-size--1x1o4 {
  .o-els-icon-svg {
    height: vars.$one-and-quarter-space;
    width: vars.$one-and-quarter-space;
  }
}

.o-els-icon-svg--1x1o4 {
  height: vars.$one-and-quarter-space;
  width: vars.$one-and-quarter-space;
}

.u-tsp-visibility--hidden {
  visibility: hidden;
}

.o-els-icon-svg--1x1o8 {
  height: 1.125rem !important;
  width: 1.125rem !important;
}

.c-tsp-modal {
  &__wrapper {
    overflow: auto;
  }

  &__window {
    overflow: initial;
    max-height: inherit;
  }
}

.u-box-shadow-none {
  box-shadow: none !important;
}

.c-tag-group__tag {
  color: colors.$els-color-n9;
  background-color: colors.$els-color-n1;
  padding: 0.125rem 0.75rem 0;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  border-radius: 0;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.4rem;
}

.u-tsp-text-line-through {
  text-decoration: line-through;
}

.u-tsp-text-highlighted {
  border: 1px solid;
  background-color: colors.$els-color-extended-yellow-1;
  padding: spaces.$els-space-1o8;
}

.c-tsp-icon {
  &__comment {
    border: none;

    &:hover {
      color: colors.$els-color-primary;
    }
  }

  &__solid-comment {
    border: none;

    &:hover {
      border: none;
    }

    img {
      width: spaces.$els-space-1x1o2 !important;
      max-width: none;

      &:hover {
        filter: invert(47%) sepia(93%) saturate(485%) hue-rotate(342deg) brightness(92%) contrast(98%);
      }

      &--medium-size {
        width: spaces.$els-space-2x !important;

        &:hover {
          filter: invert(47%) sepia(93%) saturate(485%) hue-rotate(342deg) brightness(92%) contrast(98%);
        }
      }
    }
  }
}

.u-tsp-background-color-extended-blue {
  background: vars.$tsp-color-extended-blue;
}
