@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";

.c-tsp-invoice-editor {

  &__trash:disabled,
  &__btn-edit:disabled {
    border: none;
    color: colors.$els-color-n2;
  }

  &__lock-message {
    display: flex;
    align-items: center;
  }

  &__table {
    border-bottom: keylines.$els-thick-keyline-width solid colors.$els-color-n2;

    .c-els-table {
      table-layout: fixed;

      .c-els-table__head .c-els-table__row--head .c-els-table__cell {
        &:first-child {
          width: 22%;
        }

        &:nth-child(4):not(:last-child) {
          width: 12%;
        }
      }

      .c-els-table__body .c-els-table__cell {
        overflow-wrap: break-word;

        &--nowrap {
          overflow-wrap: unset;
        }
      }
    }
  }

  &__invoice-total {
    display: flex;
    justify-content: space-between;
    margin-top: spaces.$els-space;

    &__value {
      padding-right: spaces.$els-space;

      @include sass-mq-config.mq($until: tablet) {
        min-width: spaces.$els-space-5x;
      }
    }
  }

  &__invoice-date {
    margin-top: spaces.$els-space-2x;
  }

  &__border-invoice-remaining {
    border: keylines.$els-thin-keyline-width solid;
    border-top: keylines.$els-thick-keyline-width solid colors.$els-color-primary;
  }
}

.common_left {
  text-align: right;
}

.common_right {
  text-align: left;

  li:nth-child(2) {
    white-space: nowrap;
  }
}

#editAddress {
  background: rgba($color: colors.$els-color-n4, $alpha: 0.4);
}

.c-els-field--disabled {
  .c-els-field__input {
    &:hover {
      border-color: colors.$els-color-n3;
    }
  }
}

#createInvoice,
#editInvoice {
  @include sass-mq-config.mq($from: tablet) {
    .c-els-modal__window {
      width: 700px !important;
    }
  }
}