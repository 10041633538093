@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/settings/spaces";

.c-tsp-paginator {
  margin: 15px 0px;
  .c-els-link--navigation {
    border: 0;
    :hover {
      cursor: pointer;
    }
  }
  &__result-per-page {
    @include sass-mq-config.mq($until: tablet) {
      margin-top: 10px;
    }
    :hover {
      cursor: pointer;
    }
  }

  #field-input-ddlResultsPerPage {
    border: none;
    border-bottom: keylines.$els-thick-keyline-width solid transparent;
    padding: spaces.$els-space-1o4 2.75rem 0 spaces.$els-space-3o4;
    height: spaces.$els-space-2x;
    &:hover {
      border-bottom-color: colors.$els-color-primary;
    }
  }
}
