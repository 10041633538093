@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";

#tblInstitutionInvoices {
  .c-els-table__row {
    .c-els-table__cell {
      @include sass-mq-config.mq($from: tablet) {
        &:first-child {
          width: 5%;
        }

        &--expanded {
          border-bottom: keylines.$els-thin-keyline-width solid colors.$els-color-n2;

          &:first-child {
            border-bottom: none;
          }
        }
      }

      &--field-expanded {
        @include sass-mq-config.mq($until: mobile) {
          display: table-cell;

          .u-tsp-visibility--hidden {
            display: none !important;
          }
        }

        @include sass-mq-config.mq($from: desktop) {
          padding-left: 0;
          padding-right: 0;
          background-color: transparent !important;
        }
      }
    }
  }

  .c-tsp-installment-invoices {
    .c-els-table--list__header {
      display: none;
    }

    @include sass-mq-config.mq($from: desktop) {
      .u-els-width-4o4\@wide {
        overflow: hidden;
      }
    }
  }
}

#tblInvoices {
  .c-els-table {
    &__row, &:last-child {
      .c-els-table__cell {
        &-header {
          @include sass-mq-config.mq($until: tablet) {
            border-bottom: keylines.$els-thick-keyline-width solid colors.$els-color-n2;
          }
        }

        &:last-child {
          .c-els-table__cell-content {
            border-bottom: none;
          }
        }
      }
    }
  }

  @include sass-mq-config.mq($from: tablet) {
    margin-left: 5%;

    .c-els-table {
      &__row {
        &:last-child {
          .c-els-table__cell {
            border-bottom: none !important;

            &--header {
              border-bottom: keylines.$els-thick-keyline-width solid colors.$els-color-n2 !important;
            }
          }
        }

        &:hover {
          .c-els-table__cell {
            background-color: colors.$els-color-n0 !important;

            &--header {
              background-color: transparent !important;
            }
          }
        }
      }

      &__cell {
        background-color: transparent !important;
        border-bottom: keylines.$els-thick-keyline-width dashed colors.$els-color-n2 !important;

        &--header {
          border-bottom-style: solid !important;

          &:nth-child(1) {
            width: 5%;
          }

          &:nth-child(2) {
            width: 25%;
          }

          &:nth-child(3) {
            width: 20%;
          }

          &:nth-child(4) {
            width: 30%;
          }

          &:nth-child(5) {
            width: 20%;
          }
        }
      }
    }
  }
}
