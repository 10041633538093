@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";

.c-tsp-package {
  display: flex;
  align-items: center;

  &__tooltip {
    min-width: 30%;
    max-width: 30%;

    @include sass-mq-config.mq($until: tablet) {
      max-width: 80%;
    }
  }

  &__add-ons {
    list-style: disc;
    padding-inline-start: spaces.$els-space;
    &-item {
      list-style: disc;
    }
  }

  &__icon {
    color: colors.$els-color-secondary;
    &:hover {
      color: colors.$els-color-primary;
    }
  }
}
