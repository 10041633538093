@use "~@els/els-styleguide-core/scss/settings/spaces";

.c-resource-completed-icon {
  position: relative;
  display: flex;
  margin-left: - spaces.$els-space-1o4;

  &-checkmark {
    position: absolute;
    left: spaces.$els-space-3o4;
    top: 10px;
  }
}
