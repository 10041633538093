@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";

.c-tsp-hesi-facet-container .c-els-accordion__button-text {
  border: none;
}

.c-tsp-hesi-button__min-width {
  min-width: spaces.$els-space * 10;
}

.c-tsp-exam-order__filter {
  &-closed-cbx,
  &-export-btn {
    @include sass-mq-config.mq($from: tablet) {
      display: flex;
      justify-self: flex-end;
    }
  }
}

.c-els-field__icon--left {
  pointer-events: auto !important;
  color: colors.$els-color-secondary;

  &:hover {
    color: colors.$els-color-text !important;
  }
}

.u-els-exam-order-filter-date {
  .c-els-field__input:hover,
  .c-els-field__input:focus,
  .c-els-field__wrap:hover .c-els-field__input {
    padding-right: 2.75rem !important;
  }
}

.u-els-exam-order-filter-search {
  .c-els-field__input:hover,
  .c-els-field__input:focus,
  .c-els-field__wrap:hover .c-els-field__input {
    padding-left: 2.685rem !important;
  }
}

.c-hesi-exam-order-payment-table {
  &__column {
    &--0 {
      width: 45%;
    }

    &--1 {
      width: 35%;
    }
  }
}

#tblExamOrderDashboard {
  th {
    &.c-examOrder-dashboard {
      &__table {
        &__column {
          &--0 {
            width: 0;

            &.c-els-table__cell--sortable {
              .c-els-table__sortable-button {
                display: flex;
                align-items: flex-end;
                flex-direction: row-reverse;
                justify-content: left;

                .c-els-table__sortable-button-text {
                  position: relative;
                  border: none;
                  padding: 10px;
                  left: -30px;
                  right: -28px;
                }
              }
            }
          }

          &--1 {
            width: 15%;
          }

          &--2 {
            width: 12%;
          }

          &--3 {
            width: 12%;
          }

          &--4 {
            width: 17%;
          }

          &--5 {
            width: 10%;
          }

          &--6 {
            width: 12%;
          }

          &--7 {
            width: 12%;
          }

          &--8 {
            width: 10%;
          }
        }
      }
    }
  }
  .c-els-table__cell--nowrap {
    white-space: nowrap;
  }
}

