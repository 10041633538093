@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";

.c-tsp-hesi-header {
  height: spaces.$els-space-5x;

  @include sass-mq-config.mq($until: mobile) {
    margin: 0 !important;
  }

  &__navbar {
    height: inherit;

    &__brand {
      height: inherit;
    }
  }

  &__logo {
    height: spaces.$els-space * 2;
    padding-right: spaces.$els-space * 8;

    @include sass-mq-config.mq($until: mobile) {
      padding-right: spaces.$els-space * 4;
    }
  }
}
