// Icons
$icon-size-ratio: 1;

// Spacing
$component-spacing: 1;

@function spacing($value, $multiplier) {
  @return ($value * $multiplier) + rem;
}

$quarter-space: spacing($component-spacing, 0.25); //  5px @ 20px base
$half-space: spacing($component-spacing, 0.5); // 10px @ 20px base
$three-quarters-space: spacing($component-spacing, 0.75); // 15px @ 20px base
$single-space: spacing($component-spacing, 1); // 20px @ 20px base
$one-and-quarter-space: spacing($component-spacing, 1.25); // 25px @ 20px base
$one-and-one-half-space: spacing($component-spacing, 1.5); // 30px @ 20px base
$double-space: spacing($component-spacing, 2); // 40px @ 20px base
$double-and-one-half-space: spacing($component-spacing, 2.5); // 50px @ 20px base
$triple-space: spacing($component-spacing, 3); // 60px @ 20px base

$component-spacing: 1;
$single-space: spacing($component-spacing, 1);

$mq-base-font-size: 20px;

// colors
$els-table-row-highlight: #e2e2e2;
$tsp-color-white: #fff;
$tsp-color-extended-blue: #d7e9ef;
