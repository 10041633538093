@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/settings/typography";

.iti--allow-dropdown {
  width: 100%;
}

.iti__tel-input__title {
  display: block;
  line-height: 1.2;
}

.iti__tel-input {
  margin: 0;
  width: 100%;
  max-width: 100%;
  display: block;
  vertical-align: top;
  background-color: colors.$els-color-background;
  font-size: typography.$els-fs-base;
  font-weight: normal;
  line-height: 1.4;
  opacity: 1;
  border-top: keylines.$els-thin-keyline-width solid colors.$els-color-n7;
  border-left: keylines.$els-thin-keyline-width solid colors.$els-color-n7;
  border-right: keylines.$els-thin-keyline-width solid colors.$els-color-n7;
  border-bottom: keylines.$els-thick-keyline-width solid colors.$els-color-n10;
  box-sizing: border-box;
  height: 2.5rem;
  padding: 1px spaces.$els-space-3o4 0 spaces.$els-space-3o4;
  border-radius: 0;
  color: colors.$els-color-n10;
  transition: border-color 0.2s ease;

  :hover,
  :focus {
    padding: 0 0.6875rem 0;
    border-color: colors.$els-color-n10;
    border-width: keylines.$els-thick-keyline-width;
  }
}

.iti__tel-input--readonly {
  padding: 0 !important;
  border: none !important;
  height: spaces.$els-space-1x1o2;
}