@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/settings/keylines";

.c-hesi-border-top {
  border-top: keylines.$els-thick-keyline-width solid colors.$els-color-n2;
}

#hesiExamOrderCart {
  .c-els-table__head th,
  .c-els-table__body tr td {
    &:first-child {
      padding-left: 0 !important;
    }
    &:last-child {
      padding-right: 0 !important;
    }
  }
} 