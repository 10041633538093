@use "~@els/els-styleguide-core/scss/settings/colors";

.c-tsp-side-drawer-model__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(colors.$els-color-n2, 0.85);
}
