@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";

.c-tsp-review-cohort-counts {
  &__comment {
    width: 80%;
  }

  &__actions {
    display: flex;
    align-items: center;

    &--reject {
      background-color: #FFFFFF;
      color: colors.$els-color-warn;
    }
  }
  &__no-results {
    padding: spaces.$els-space;
    border-top: keylines.$els-thin-keyline-width solid colors.$els-color-n3;
    border-bottom: keylines.$els-thin-keyline-width solid colors.$els-color-n3;
  }
}
