@use "~@els/els-styleguide-core/scss/settings/colors";

.c-tsp-cohort-hesi-detail {
  .c-els-accordion__group {
    border: none !important
  }

  .c-els-accordion__button {
    &:focus {
      outline: none !important
    }

    &:hover {
      .c-els-accordion__button-text {
        color: colors.$els-color-primary
      }
    }

    &-text {
      color: colors.$els-color-secondary
    }
  }

  .c-els-accordion__group--expanded {
    .c-els-accordion__button-text {
      color: colors.$els-color-primary
    }

    .c-els-accordion__body {
      padding-left: 0
    }
  }
  
  .c-tsp-cohort-hesi__contract--cancelled {
    &:hover {
      cursor:pointer;
     }
  }
}
