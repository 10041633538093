@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";
@use "base/vars";

.c-els-pagination__button,
.c-els-pagination__active-page {
  min-width: 40px;
}

.c-els-pagination__disabled-content {
  color: #cecece;
}

.o-els-flex-layout--column\@tablet {
  @include sass-mq-config.mq($until: tablet) {
    flex-direction: column;
  }
}

.c-els-accordion {
  &__button:focus {
    outline: 2px solid colors.$els-color-primary!important;
    outline-offset: 0;
  }
}

.c-els-table {
  &__cell--expanded {
    border-bottom: 2px dashed vars.$els-table-row-highlight;
  }

  &__sortable-button {
    &:hover {
      color: colors.$els-color-secondary;
    }
  }
  &__sort-icon {
    border-bottom: 2px solid transparent;
  }
}

.c-els-field {
  &--error {
    .c-datepicker-input {
      input {
        color: colors.$els-color-warn;
      }
    }
  }

  &--disabled {
    .c-datepicker-icon {
      color: colors.$els-color-n6 !important;

      &:hover {
        color: colors.$els-color-n6 !important;
      }
    }
  }
}

.c-els-field--checkbox.c-tsp-checkbox {
  .c-els-field__switch {
    height: vars.$one-and-quarter-space;
    width: vars.$one-and-quarter-space;
    border: keylines.$els-thick-keyline-width solid colors.$els-color-n10;
  }
  .c-els-field__label-text-content {
    display: inline-block;
    margin-top: spaces.$els-space-1o8;
  }
  .c-els-field__input:checked + .c-els-field__label-text .c-els-field__switch:after {
    width: calc(100% - #{spaces.$els-space-1o4});
    height: calc(100% - #{spaces.$els-space-1o4});
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}

.c-datepicker-icon {
  pointer-events: auto !important;
  color: colors.$els-color-secondary;
  &:hover {
    color: colors.$els-color-text !important;
  }
}

.c-dropdown-search {
  .c-els-field__input:disabled {
    border-color: colors.$els-color-n3 !important;
  }

  &__option {
    z-index: 2;
    &-item {
      cursor: pointer;
    }
  }
}
