@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";

#requestAddressChangesModal .c-els-modal__window {
  @include sass-mq-config.mq($from: tablet, $until: desktop) {
    width: 60% !important;
  }

  .confirm-address {
    width: spaces.$els-space-5x;
  }
}

.request-address-change {
  &__note {
    border: keylines.$els-thin-keyline-width solid colors.$els-color-secondary;
    border-top: keylines.$els-thick-keyline-width solid colors.$els-color-secondary;
    border-radius: 3px;
  }
}
