@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";

.c-tsp-table-expand {
  &__row {
    display: flex;
    flex-direction: row;

    &:last-child {
      .c-tsp-table-expand__cell {
        border-bottom: keylines.$els-thin-keyline-width solid colors.$els-color-n2;
      }
    }
  }

  &__cell {
    padding: spaces.$els-space;
    flex: 1;
    border-top: keylines.$els-thin-keyline-width solid colors.$els-color-n2;

    &-header {
      display: none;
    }

    &-expand {
      flex: none;
    }
  }

  &__expand-row {
    padding-bottom: spaces.$els-space;
  }

  @include sass-mq-config.mq($until: tablet) {
    &--list\@tablet {
      .c-tsp-table-expand__row {
        flex-direction: column;
        &:first-child {
          .c-tsp-table-expand__cell {
            &-header,
            &-content {
              border-top: none;
            }
          }
        }
      }

      .c-tsp-table-expand__cell {
        padding: 0;
        border: none !important;
        display: flex;
        flex-direction: row;

        &:first-child {
          &-header,
          &-content {
            padding-top: spaces.$els-space;
            border-top: keylines.$els-thin-keyline-width solid colors.$els-color-n2;
          }
        }

        &-header {
          flex: 1 30%;
          min-width: spaces.$els-space-5x;
          display: block;
          padding: spaces.$els-space-1o2;
          padding-left: 0;
          border-bottom: keylines.$els-thick-keyline-width solid colors.$els-color-n2;
        }

        &-content {
          flex: 1 70%;
          padding: spaces.$els-space-1o2;
          padding-right: 0;
        }

        .c-tsp-table-expand__btn {
          padding: spaces.$els-space-1o2 spaces.$els-space-1o2 spaces.$els-space-1o2 0;
        }
      }
    }
  }

  @include sass-mq-config.mq($until: mobile) {
    &--list\@mobile {
      .c-tsp-table-expand__row {
        flex-direction: column;
      }

      .c-tsp-table-expand__cell {
        padding: 0;
        border: none !important;
        display: flex;
        flex-direction: row;

        &:first-child {

          &-header,
          &-content {
            padding-top: spaces.$els-space;
          }
        }

        &-header {
          flex: 1 30%;
          min-width: spaces.$els-space-5x;
          display: block;
          padding: spaces.$els-space-1o2;
          padding-left: 0;
          border-bottom: keylines.$els-thick-keyline-width solid colors.$els-color-n2;
        }

        &-content {
          flex: 1 70%;
          padding: spaces.$els-space-1o2;
          padding-right: 0;
        }

        .c-tsp-table-expand__btn {
          padding: spaces.$els-space-1o2 spaces.$els-space-1o2 spaces.$els-space-1o2 0;
        }
      }
    }
  }
}
