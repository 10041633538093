@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "../base/vars";

.c-tsp-status {
  &__icon {
    display: inline-block;
    width: vars.$one-and-quarter-space;
    height: vars.$one-and-quarter-space;
    vertical-align: middle;
    margin-right: spaces.$els-space-1o2;
    border-radius: 50%;
  }
}
