@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";

.c-tsp-opportunity-section {
  &__item {
    margin-bottom: spaces.$els-space-1o2;

    .c-els-table {
      &__cell {
        padding: 0;
        border-bottom: none;

        &:first-child {
          width: 1px;
          white-space: nowrap;
        }
      }
    }

    &--center {
      .c-els-table__cell {
        vertical-align: middle;
      }
    }
  }
}

.c-tsp-opportunity-installments {
  &__checkbox {
    width: spaces.$els-space;
    padding: 0;
  }

  &__cancel {
    vertical-align: middle;
  }

  &__comment {
    width: spaces.$els-space-3x;
    padding: 0;
  }

  #tblInstallments {
    table-layout: fixed;

    .c-els-table__row>.c-els-table__cell:first-child {
      padding-left: 0;
    }

    .c-els-table__cell__break__word {
      overflow-wrap: break-word;
    }

    .c-els-table__cell__anyware {
      overflow-wrap: anywhere;
    }
  }

  &__compile-installment-tooltip {
    .c-els-flyout__window {
      border-top: keylines.$els-thick-keyline-width solid colors.$els-color-warn;
    }

    &-content {
      max-width: 320px;
    }
  }

  &__warning {
    display: inline-flex;

    &-message {
      margin-top: spaces.$els-space-1o4;
    }
  }
}

.c-tsp-opportunity-invoices {
  &__edit-date {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100%;

    &__date {
      position: relative;
      display: block;

      .c-els-field__input {
        height: 2.6rem;
        line-height: 2.6rem;
      }
    }

    &__icon {
      display: flex;
      margin-left: spaces.$els-space;
      margin-right: spaces.$els-space-1o2;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;

    @include sass-mq-config.mq($from: tablet) {
      flex-direction: column;
      align-items: center;
    }

    .icon-money-refund {
      width: spaces.$els-space-2x !important;
    }
  }

  &__invoice-statuses {
    min-width: 250px !important;
    list-style: disc;
    padding-inline-start: spaces.$els-space;
    margin-left: spaces.$els-space-1o2;
    margin-top: spaces.$els-space-1o4;

    &--item {
      list-style: disc;
      line-height: 1.5;
    }
  }

  #tblCohortInvoices {
    .c-els-table__row>.c-els-table__cell:first-child {
      padding: spaces.$els-space 0 spaces.$els-space spaces.$els-space;
      vertical-align: middle;
    }
  }
}

.c-tsp-invoice-status {

  &__block,
  &__edit {
    flex: none;
  }
}

.c-tsp-opportunity-faculty-staff {
  .roster-submitted {
    &__error {
      display: flex;
      border: keylines.$els-thick-keyline-width solid colors.$els-color-confirm;

      &-icon {
        flex-basis: spaces.$els-space-2x;
        margin-right: spaces.$els-space-1o2;
      }

      &-item--status {
        text-transform: capitalize;
      }
    }
  }
}

#editDateModal {
  overflow: auto;

  .c-els-modal__window {
    overflow: initial;
  }
}

#editProductModal {
  overflow: auto;
  align-items: unset;
  padding: spaces.$els-space-2x 0;

  .c-els-modal__window {
    overflow: initial;
    max-height: inherit;
    margin-top: auto;
    margin-bottom: auto;
  }
}

#statusHistory {
  .c-els-modal__window {
    @include sass-mq-config.mq($from: tablet) {
      width: 80% !important;
    }
  }
}

.c-tsp-opportunity-page {
  &__button-transparent {
    background-color: transparent;
    color: colors.$els-color-secondary;
  }

  &__in-flight-cohort-message {
    background-color: #d7e9ef;
  }
}

#cohortCountApprovalWarn {
  .c-els-modal__window {
    border-top-color: colors.$els-color-warn;

    .c-els-modal__close {
      color: #2e2e2e;
      position: relative;
    }
  }
}

#studentGroupModal {
  overflow: auto;

  .c-els-modal__window {
    overflow: initial;
  }
}

.c-tsp-display {
  display: -webkit-box !important;
}

.c-tsp-light-blue {
  background-color: #e0f8fd;
}

.c-tsp-installment-comment-modal {
  .comment-icon {
    width: spaces.$els-space-2x;
    margin-top: spaces.$els-space-1o8;
  }
}

.c-els-tab-group {
  .c-els-tab-group__item {
    &--disabled {
      .c-els-tab-group__text {
        color: colors.$els-color-n3 !important;
      }
    }
  }

  .c-els-tab-group__text:hover {
    cursor: pointer;
  }
}


.c-tsp-my-dashboard,
.c-tsp-credit-request-queue {
  .o-els-flex-layout__item--grow {

    .c-els-field__input:hover,
    .c-els-field__input:focus,
    .c-els-field__wrap:hover .c-els-field__input {
      padding-left: 2.685rem !important;
    }
  }
}
