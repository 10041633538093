@use '~@els/els-styleguide-core/scss/core'as *;
@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";

.c-tsp-credit-request {
  @extend .o-els-flex-layout;
  @extend .u-els-width-1o1\@tablet;
  width: 65%;
  margin: spaces.$els-space auto;
  padding: spaces.$els-space;

  &-custom {
    width: 40%;
  }

  .c-els-credit-layout {
    flex-basis: 24%;
    min-width: 24%;
    margin-right: spaces.$els-space-2x;

    &__item {
      margin-bottom: spaces.$els-space;
      border-left: keylines.$els-thick-keyline-width solid transparent;
      padding-left: spaces.$els-space-1o2;

      &:last-child {
        margin-bottom: spaces.$els-space-1o2;
      }

      &__anchor {
        color: colors.$els-color-n10 !important;
        background-color: white;
        text-align: left;
        border: none!important;
      }

      &--active {
        border-left: $els-thick-keyline-width solid $els-color-primary;
        border-bottom: none !important;
      }

      &:hover {
        color: colors.$els-color-primary !important;
        border-left: $els-thick-keyline-width solid $els-color-primary;
        cursor: pointer;
        .c-els-anchor__text {
          color: colors.$els-color-primary !important;
        }
      }

      .c-els-anchor__text {
        &:hover {
          color: colors.$els-color-primary !important;
          border-bottom: none !important;
        }
      }
    }

    &__left-menu {
      border-bottom: $els-thin-keyline-width solid $els-color-n3;
    }
  }

  .c-els-margin-align {
    margin: auto;
  }

  .o-els-container {
    flex-grow: 1;
  }

  &__address {
    @extend .o-els-flex-layout;
    @extend .o-els-flex-layout--wrap;
    @extend .o-els-flex-layout--left;
    @extend .u-els-margin-bottom;

    &--item {
      @extend .o-els-flex-layout__item;
      @extend .u-els-min-width-1o3;
      @extend .u-els-max-width-1o3;
      @extend .u-els-min-width-1o1\@mobile;

      .c-els-field__label-text {
        font-weight: bold;
        margin-bottom: spaces.$els-space-1o4;
      }
    }
  }

  &-confirmation {
    &__content {
      width: calc(100% - (#{spaces.$els-space} * 2.5));
    }

    &__requester-details {
      padding: spaces.$els-space 0;

      &-item {
        @extend .o-els-flex-layout;
        @extend .o-els-flex-layout--left;

        &--label {
          @extend .o-els-flex-layout__item;
          @extend .u-els-min-width-1o4;
          @extend .u-els-max-width-1o4;
          @extend .u-els-min-width-1o4\@tablet;
          @extend .u-els-max-width-1o4\@tablet;
          @extend .u-els-min-width-1o2\@desktop;
          @extend .u-els-max-width-1o2\@desktop;
          @extend .u-els-min-width-1o2\@mobile;
          @extend .u-els-max-width-1o2\@mobile;
          @extend .u-els-text-right;
          margin-right: spaces.$els-space;
        }

        &--value {
          @extend .o-els-flex-layout__item--grow;
        }
      }
    }
  }

  &-track-details {
    &__tooltip {
      z-index: 10;

      &-status-item {
        margin-left: spaces.$els-space-1x1o2;
        list-style: disc;
        line-height: 1.5;
      }
    }
  }

  &-attachment {
    &__link-download {
      display: none;
    }

    &__btn-download {
      word-break: break-all;

    }
  }

  &-attachments {
    &__file {
      @extend .o-els-flex-layout;
      @extend .o-els-flex-layout--space-between;
      @extend .o-els-flex-layout--wrap\@mobile;

      &-name {
        @extend .o-els-flex-layout__item;
        @extend .u-els-color-secondary;
        @extend .u-els-margin-right;
        flex-grow: 1;
      }
    }
  }

  &__icon-confirmation {
    position: absolute;
    z-index: 10;
    right: 0;
    margin-right: spaces.$els-space-1x;
    margin-top: spaces.$els-space-3o4;
  }
}

#field-wrap-invoiceNumber,
#field-wrap-creditRequestNumber {
  .c-els-field__label-text {
    opacity: 1;

    .invoice-number-field-text,
    .credit-request-number-field-text {
      opacity: .5;
    }
  }

  .c-els-field__input {
    opacity: 1;
  }
}

.tsp-validate-invoice-number {
  &__invoice-number {
    display: inline-flex;
  }

  &__invoice-status-tooltip {
    & .image-class {
      width: 650px;

      @include sass-mq-config.mq($until: tablet) {
        width: 250px;
      }
    }
  }
}
