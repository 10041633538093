@use '@els/els-styleguide-core/scss/core' as *;
@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";

.c-tsp-student-payment {
  &-confirmation {
    &__message {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: spaces.$els-space-3o4 0;
      background-color: rgba($color: colors.$els-color-secondary-on-dark, $alpha: 0.1);
      margin-bottom: spaces.$els-space;
    }
  }

  &-user-profile {
    @extend .o-els-container;
    @extend .u-els-padding-1x1o2;
    @extend .u-els-max-view-width-3o4;
    @extend .u-els-max-view-width-9o10\@desktop;
    @extend .u-els-max-view-width-1o1\@tablet;
    margin: auto;

    &__subtitle {
      @extend .u-els-color-primary;
      @extend .u-els-font-family-base;

      margin-bottom: spaces.$els-space-1o2;
    }

    &__info {
      @extend .o-els-flex-layout;
      @extend .o-els-flex-layout--wrap;
      @extend .o-els-flex-layout--left;
      @extend .u-els-margin-bottom;
      &--item {
        @extend .o-els-flex-layout__item;
        @extend .u-els-min-width-1o3;
        @extend .u-els-max-width-1o3;
        @extend .u-els-min-width-1o1\@mobile;

        .c-els-field__label-text {
          font-weight: bold;
          margin-bottom: spaces.$els-space-1o4;
        }
      }
    }
  }

  &-progress {
    &-button {
      margin-left: auto;
      margin-bottom: 5px;
    }
  }
  &-billing-address {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  &-error-message {
    max-width: fit-content;
  }
}